.site-main{
	.pane-node-body{
		.external-link{
			//EXTERNAL LINK OVERRIDE
			&:after{
				background-image: url('/profiles/wcmc/themes/wcm_brand_base/images/cta_icons/SVG/external-link.svg');
			    background-size: 15px;
			    background-repeat: no-repeat;
			    display: inline-block;
			    width: 15px;
			    height: 15px;
			    content:"";
			    margin-left: 5px;
			    margin-right: 10px;
			}
		}
	}

	//INFORMATION COLUMN
	.information-column{

		//BUTTON PADDING
		.btn--override{
			margin-bottom: 20px;
		}
	}

	//RELATED CONTENT
	#related-content-sidebar{
		//RELATED LINKS
		.pane-node-field-related-links{
	    	a{
		        display: table;
    			clear: both;

			    &:before{
			    	content: "\2022";
			    	color: $wcm-black;
			    	margin-right: 10px;
			    }
			}
		}

	}

}

/* =Default Select Elements
----------------------------------------------------------*/

.form-select {
  max-width: none;
  height: 50px;
  border: 1px solid $wcm-border-gray;
  box-shadow: none;
  appearance: none;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3E%3Cpath d='M207 381.5L12.7 187.1c-9.4-9.4-9.4-24.6 0-33.9l22.7-22.7c9.4-9.4 24.5-9.4 33.9 0l154.7 154 154.7-154c9.4-9.3 24.5-9.3 33.9 0l22.7 22.7c9.4 9.4 9.4 24.6 0 33.9L241 381.5c-9.4 9.3-24.6 9.3-34 0z' fill='%23cf4520'/%3E%3C/svg%3E");
	background-repeat: no-repeat;
	background-position: right 15px top 50%;
  background-size: 20px 20px;
  padding-right: 50px;
}
