.not-front{
	.site-main{

		//GUIDANCE VIEWS
		.view-guidance{
			.views-row{
				display: inline-block;
				padding: 15px 15px 15px 0px;

				.views-field-field-featured-image{
					img{
						margin-right: 20px;
					}
				}

				h3.teaser-title{
				    margin: 0px;
				}
			}
		}

		//FORMS VIEWS
		.view-forms{
			.views-row{
				padding: 25px 0px;
				border-bottom: solid 1px $wcm-border-gray;

				@mixin icon_override(){
				    background-position: center right;
				    background-repeat: no-repeat;
				    padding: 5px 35px 5px 0px;
				    object-fit: margin;
				    background-size: 25px;
			  	}

			  	a{
					&:after{
						content: '\25b8';
						font-size: 20px;
						padding-left: 10px;
					}
				}

				a[href*=".pdf"]{
			    	@include icon_override();
			    	background-image: url('/profiles/wcmc/themes/wcm_brand_base/images/cta_icons/SVG/pdf.svg');
			    	&:after{
						content: none;
					}

			  	}

			  	a[href*=".doc"], a[href*=".docx"]{
			    	@include icon_override();
			    	background-image: url('/profiles/wcmc/themes/wcm_brand_base/images/cta_icons/SVG/doc.svg');
			    	&:after{
						content: none;
					}
			  	}

			  	a[href*=".xls"], a[href*=".xlsx"]{
			    	@include icon_override();
			    	background-image: url('/profiles/wcmc/themes/wcm_brand_base/images/cta_icons/SVG/doc.svg');
			    	&:after{
						content: none;
					}
			  	}

			  	a[href*=".ppt"], a[href*=".pptx"]{
			    	@include icon_override();
			    	background-image: url('/profiles/wcmc/themes/wcmc_bootstrap/images/cta_icons/SVG/doc.svg');
			    	&:after{
						content: none;
					}
			  	}


			}

			.views-row-last{
				border: none;
			}
		}

		/*NEWS LIST VIEW*/
		.view-news{
			.views-row{
				padding: 0px 0px 40px 0px;
				margin: 40px 0px 0px 0px;
			    border-bottom: solid 1px $wcm-border-gray;
				.teaser-image{
					img{
						float: right;
						margin: 0px 10px;
					}
				}
				h3.teaser-title{
					margin: 0px;
				}

				a.read-more{
					padding: 20px 0px 0px 0px;
		    		display: inline-block;
				}
			}

			.views-row-last{
				border-bottom: none;
			}
		}

		.node-type-news-post{
			.hero-image{
				img{
				    width: auto;
				}
			}
		}

		.guidance_cta{
		    display: inline-block;

		    h3{
	    	    padding-top: 20px;
		    }
		}
	}
}
