.front{

	@mixin homepage_h2_header{
		h2{
			padding-bottom: 25px;
			margin: 0px;

			&:before{
		        content: "";
			    background: $wcm-bright-orange;
			    position: absolute;
			    height: 5px;
			    width: 50px;
			    margin: 12px 0px 0px -75px;
			}
		}
	}

	.welcome_container{
		margin-top: 30px;
		margin-bottom: 30px;
		padding: 0px;

		.homepage_welcome_image{
			height: auto;
			overflow: hidden;

			@include breakpoint ($md){
				padding-left: 0px;
			}

			@include breakpoint ($lg){
				padding-left: 0px;
			}

			img{
				width: 100%;
			}
		}

		.homepage_welcome_content{
			@include homepage_h2_header();
			padding-left: 90px;
			
			@include breakpoint ($md){
				padding-top: 72px;
			}

			@include breakpoint ($lg){
				padding-top: 72px;
			}
		}
	}

	.contact_us_container{
		margin-bottom: 30px;

		//CTA
		.homepage_contact_cta{
			
			.cta-button{
				background-color: $wcm-bright-orange;

				ul{
					list-style: none;
	    			text-align: center;

	    			li{
	    				font-size: 22px;
					    font-weight: bold;
					    margin: 0px;
					    
					    a{
					    	color: $wcm-white;
	    					border-bottom: none;
	    					width: 100%;
					        display: inline-block;
						    height: 100px;
						    padding: 35px 0px;

	    					&:hover{
	    						text-decoration: none;
	    					}

	    					&:after{
	    						content: '\f105';
								font-family: "fontello";
								color: $wcm-white;
								font-size: 30px;
								line-height: 30px;
						        padding-left: 25px;
	    					}
					    }
	    			}
				}
			}
		}
	}

	.insurance_container{
		min-height: 535px;
		background-color: $wcm-bg-gray;
		margin-bottom: 30px;
		padding: 50px;
		
		.row{
			margin: 0px;
			.homepage_insurance{
				@include homepage_h2_header();
				padding: 0px;

				.insurance_inner_container{
					padding: 35px 50px;
					background-color: $wcm-white;
					
					@include breakpoint ($md){
						width: 100%;
						float: right;
					}

					@include breakpoint ($lg){
						width: 80%;
						float: right;
					}

					.insurance-links{
						ul{
							list-style: none;
							li{
							    font-weight: 700;
							    letter-spacing: 2px;
							    text-transform: uppercase;
							    padding: 15px 0px;
							    margin: 0px;
							    border-bottom: solid 1px $wcm-border-gray;

							    a{
							    	color: $wcm-med-gray;
							    	border-bottom: none !important;

							    	&:after{
							    		content: '\e80d';
									    padding-left: 10px;
									    color: $wcm-bright-orange;
									    font-family: "fontello";
									    font-style: normal;
									    font-weight: normal;
									    font-size: 12px;
									    speak: none;
									    display: inline-block;
									    text-decoration: inherit;
									    text-align: center;
									    font-variant: normal;
									    text-transform: none;
									    line-height: 1em;
									    -webkit-font-smoothing: antialiased;
									    -moz-osx-font-smoothing: grayscale;
									    text-decoration: none;
							    	}
							    }
							}

							li.last{
								border-bottom: none;
							}
						}
					}
				}
			}

			.homepage_insurance_image{
				padding-left: 0px !important;
				padding-right: 0px !important;

				img{
				    @include breakpoint ($xs){
				    	width: 100%;
				    	height: auto;
				    }

				    @include breakpoint ($sm){
				    	width: 100%;
				    	height: auto;
				    }

				    @include breakpoint ($md){
				    	width: auto;
				    	padding: 45px 0px;
				    }

				    @include breakpoint ($lg){
				    	width: auto !important;
				    	padding: 45px 0px;
				    }
				}
			}
		}
	}

	.guidance_container{
		min-height: 365px;
		margin-bottom: 30px;
		background-image: url('/sites/default/files/styles/panopoly_image_original/public/homepage_guidance.jpg');
		background-repeat: no-repeat;
    	background-size: cover;

		.homepage_guidance{
			@include homepage_h2_header();
			background-color: rgba(247, 247, 247, 0.6);
			opacity: 1.0;
			margin: 40px 30px;
			padding: 55px 95px;

			@include breakpoint ($md){
				margin: 70px 220px;
			}

			@include breakpoint ($lg){
				margin: 70px 220px;
			}
		}

	}
}  